<template>
  <div class="tenants-connections">
    <div class="container">
      <h2 class="title">{{ $t("contactsTitle") }}</h2>
      <h3>{{ $t("contactsContent") }}</h3>
      <div class="tenants-connections__links">
        <!-- <a
          v-for="(item, index) in $t('contactsTexts')"
          :key="index"
          :href="item.phone"
          class="tenants-connections__link"
          >{{ item.text }}</a
        > -->
        <vue-markdown :source="pageRent.contacts">
          {{ pageRent.contacts }}</vue-markdown
        >
        <!-- <a href="tel:87761755757" class="tenants-connections__link">8 776 175 57 57 Камилям (г.Алматы, мкр. Аксай-4, д.70Б, ТД НУР );</a>
        <a href="tel:87077593243" class="tenants-connections__link">8 707 759 32 43 Ирина (г.Алматы, мкр. ул. Толе би д.285, ТЦ City Plus);</a>
        <a href="tel:87770789115" class="tenants-connections__link">8 777 078 91 15 Алмас (г. Нур-Султан, г. Караганда, г. Петропавловск).</a> -->
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";
export default {
  name: "TenantsConnections",
  components: {
    VueMarkdown,
  },
  props: {
    pageRent: {
      type: Object,
      required: true,
    },
  },
};
</script>
